<style lang="scss">
@import "assets/scss/components/header.scss";
</style>

<template>
  <header>
    <div class="left">
      <img src="/images/logo.png" class="h-full" alt="Logo de la marque">
    </div>
    <div class="right">
      <UPopover v-model:open="PopoverOpen" class="popup" v-if="!isPrinter && $account && $account.subscription_plan">
        <div class="popup__text">
          <vs-button class="dropdown__button">
            <UIcon name="i-heroicons-bolt" />
            <span class="popup__span">Solde et forfait</span>
          </vs-button>
        </div>

        <template #panel>
          <div class="py-4 pl-4 pr-16">
            <h3 class="font-bold">Solde & forfait :</h3>
            <div class="pt-4 grid gap-4">
              <div>
                <p class="font-medium">{{ $account.subscription_plan?.displayname || "Aucun" }}</p>
                <NuxtLink class="little-text underline" to="/subscriptions">Gérer mon abonnement
                </NuxtLink>
              </div>
              <div>
                <p class="font-medium">Balance: {{ text2Price($account.balance) }}</p>
                <NuxtLink class="little-text underline" to="/settings/billing#credit">Créditer mon compte</NuxtLink>
              </div>
              <div>
                <p class="font-medium">Solde minimal: {{ text2Price($account.minimal_balance) }}</p>
                <NuxtLink class="little-text underline" to="/settings/billing#credit">Gérer mon solde minimal</NuxtLink>
              </div>
            </div>
          </div>
        </template>
      </UPopover>
      <div class="dropdown" v-if="!isPrinter && $account">
        <UDropdown :items="items" :popper="{ placement: 'bottom-start' }">
          <vs-button class="dropdown__button">
            <i class="isax isax-buildings"></i>
            <span>{{ $account?.displayname || "Non défini" }}</span>
            <i class="isax isax-arrow-down-1 arrow"></i>
          </vs-button>
        </UDropdown>
      </div>
    </div>
  </header>
</template>

<script setup>
import { VsButton } from "vuesax-ts";
import { text2Price } from "../utils/global.mjs";
import { fetch } from "~/utils/helper";

const PopoverOpen = ref(false);

watch(() => PopoverOpen.value, (value) => {
  if (value) {
    const auth = useAuth();
    auth.refreshAuthStatus();
  }
});

const items = [
  [{
    label: "Changer de compte",
    icon: "i-heroicons-arrows-right-left-20-solid",
    click: async () => {
      await switchAccount();
    }
  }],
  [{
    label: "Mon entreprise",
    icon: "i-heroicons-building-office-2-20-solid",
    click: () => {
      navigateTo("/settings/account");
    }
  }]
];
const { $account } = useNuxtApp();
let $swal = null;
if ($account && $account?.subscription_plan) {
  items[1] = [{
    label: "Mon entreprise",
    icon: "i-heroicons-building-office-2-20-solid",
    click: () => {
      navigateTo("/settings/account");
    }
  }, {
    label: "Mon offre",
    icon: "i-heroicons-credit-card-20-solid",
    click: () => {
      navigateTo("/settings/billing");
    }
  }];
  items.push([{
    label: "Développeurs",
    icon: "i-heroicons-wrench-20-solid",
    click: () => {
      navigateTo("/developers");
    }
  }], [{
    label: "Utilisateurs",
    icon: "i-heroicons-users-20-solid",
    click: () => {
      navigateTo("/team");
    }
  }]);
}
const switchAccount = async () => {
  if (!$swal) $swal = useNuxtApp().$swal;
  $swal.fire({
    title: "Chargement...",
    text: "Chargement des comptes...",
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    async didOpen(_) {
      const accounts = await fetchAccounts();
      // transform accounts to { id: displayname }
      $swal.fire({
        title: "Changer de compte",
        text: "Choisissez un compte pour continuer",
        input: {
          label: "Vos comptes",
          type: "select",
          options: accounts.map(account => {
            return {
              value: account.id,
              label: account.displayname
            };
          }),
          value: $account.id,
          validator: (value) => {
            if (!value) {
              return "Vous devez choisir un compte pour continuer";
            }
          }
        },
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Changer",
        cancelButtonText: "Annuler",
        showLoaderOnConfirm: true,
        async preConfirm(value) {
          const data = await fetch(`/accounts/@me`, {
            method: "POST",
            body: {
              accountId: value
            }
          });
          if (data.success) {
            window.location.reload();
          } else {
            $swal.fire({
              title: "Erreur",
              text: data.message,
              icon: "error"
            });
          }
        }
      });
    }
  });
};
const fetchAccounts = async () => {
  const data = await fetch("/accounts/@me", {
    method: "GET"
  });
  return data.accounts;
};

</script>

<script>
export default {
  name: "Header",
  props: {
    isPrinter: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fetchingAccounts: false
    };
  }
};
</script>
